.divider {
  background: linear-gradient(135deg, #667eea, #764ba2);
  color: white;
  text-align: center;
  padding: 2vh 4vw;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 1);
}

.divider h2 {
  font-size: 30px;
  font-weight: 600;
}

.divider p {
  font-size: 20px;
  font-weight: 300;
}

@media only screen and (max-width: 1250px) {
  .divider h2 {
    font-size: 28px;
  }
  .divider p {
    font-size: 18px;
  }
}

@media screen and (max-width: 768px) {
  .divider h2 {
    font-size: 24px;
  }
  .divider p {
    font-size: 16px;
  }
}
