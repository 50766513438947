.reviews {
  display: flex;
  flex-direction: column;
  margin: 0 16vw 4vh 16vw;
  gap: 4vh;
}

.reviews-empty {
  margin: auto;
  color: lightgrey;
  font-weight: 500;
}

.reviews-group {
  display: flex;
  flex-direction: row;
  gap: 2vw;
}

@media screen and (max-width: 1250px) {
  .reviews-group {
    display: flex;
    flex-direction: column;
    gap: 4vh;
  }
}

.reviews-left {
  display: flex;
  flex-direction: column;
  gap: 2vh;
  position: sticky;
  top: 4vh;
  align-self: flex-start;
  width: 32vw;
}

@media screen and (max-width: 1250px) {
  .reviews-left {
    position: relative;
    width: 100%;
    top: 0;
    gap: 4vh;
  }
}

.reviews-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 4vh;
}

.reviews hr {
  border: 1px solid #e9e9e9;
}

@media screen and (max-width: 768px) {
  .reviews {
    margin: 0 8vw 4vh 8vw;
  }
}
