.navbar-parent {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: white; /* TODO - make content behind navbar blurry */
}

.navbar {
  display: flex;
  justify-content: space-around;
  padding: 16px 0;
}

.navbar * {
  text-decoration: none;
  color: #171717;
}

.navbar-mobile {
  display: none;
}

.nav-logo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.nav-logo img {
  width: 40px;
  height: 40px;
}

.nav-logo p {
  color: #171717; /* TODO - Color variables */
  font-size: 38px;
  font-weight: 600;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  gap: 32px;
  color: grey; /* TODO - Color variables */
  font-size: 20px;
  font-weight: 500;
  padding: 0;
}

@keyframes wiggleSmall {
  0% {
    transform: rotate(0deg);
  }
  5% {
    transform: rotate(7deg);
  }
  10% {
    transform: rotate(-7deg);
  }
  15% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.nav-menu li {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nav-menu li:hover {
  animation: 2.5s ease 0s normal forwards 1 wiggleSmall;
}

.nav-menu hr {
  border: none;
  width: 80%;
  height: 3px;
  border-radius: 8px;
  background: linear-gradient(135deg, #667eea, #764ba2);
}

.settings-dropdown {
  position: absolute;
  top: calc(100%);
  right: -16px;
  background-color: white;
  border: 1px solid #ccc;
  width: 200px;
  border-radius: 16px;
  padding: 10px;
  z-index: 999;
  display: none;
}

.settings-dropdown ul {
  list-style: none;
  padding: 0;
}

.settings-dropdown ul hr {
  margin: 8px 0;
  border: none;
  width: 75%;
  height: 1px;
  text-align: center;
  background: #ccc;
}

.settings-dropdown ul li {
  padding: 8px 16px;
  cursor: pointer;
}

.settings-dropdown ul li:hover {
  background-color: #f5f5f5;
}

.nav-state-gear {
  position: relative;
}

.nav-state-gear:hover .settings-dropdown {
  display: block;
}

.nav-state {
  display: flex;
  align-items: center;
  gap: 16px;
}

.nav-state > * {
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #171717; /* TODO - Color variables */
  text-decoration: none;
}

.nav-state > *:hover {
  transform: scale(1.1);
  transition: 0.25s;
}

.nav-state-flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.nav-state-cart {
  position: relative;
}

.nav-state-cart-count {
  position: absolute;
  top: -50%;
  right: -50%;
  min-width: 32px;
  height: 32px;
  background: linear-gradient(135deg, #667eea, #764ba2);
  border: 2px solid white;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

@media screen and (max-width: 768px) {
  .navbar-mobile {
    display: flex;
  }

  .nav-menu-large {
    display: none;
  }

  .nav-state-flag {
    display: none;
  }
}
