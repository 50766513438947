.reviewinput {
  gap: 2vh;
}

.reviewinput form {
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.reviewinput form * {
  display: flex;
  flex-direction: column;
}

.reviewinput label {
  margin-right: 1vh;
  color: grey;
}

.reviewinput input {
  padding: 8px 2vh;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.reviewinput input:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reviewinput ::placeholder {
  color: lightgrey;
}

.reviewinput input:focus {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  outline: none;
}

.reviewinput input:focus::placeholder {
  color: darkgrey;
}

.reviewinput textarea {
  height: 10vh;
  padding: 1vh 2vh;
  border: none;
  background-color: #fff;
  border-radius: 8px;
  font-size: 1rem;
  font-family: inherit;
  resize: none;
  transition: all 0.3s ease;
}

.reviewinput textarea:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reviewinput textarea:focus {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  outline: none;
}

.reviewinput textarea:focus::placeholder {
  color: darkgrey;
}

.reviewinput-submit button {
  padding: 12px 2vw;
  margin-top: 1vh;
  border: none;
  border-radius: 5px;
  background-color: grey;
  color: white;
  font-size: 1rem;
  font-weight: 700;
  cursor: pointer;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  align-items: center;
  display: inline; /* Adjust width based on content */
  width: min-content; /* Allow button to shrink to fit content */
}

.reviewinput-submit button:hover {
  background-color: #3a3a3a;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
  transition: 0.25s;
}

.reviewinput-submit button:active {
  background-color: #171717;
  transform: scale(0.975);
  transition: 0.25s;
}

@media screen and (max-width: 768px) {
  .reviewinput-submit button {
    padding: 1.5vh 2vw;
    font-size: 1.2rem;
    display: block;
    width: 100%;
  }
}
