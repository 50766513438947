.shopcategory {
  display: flex;
  flex-direction: row;
}

.shopcategory-left {
  /* flex-basis: 15%; */
  display: flex;
  flex-direction: column;
  gap: 4vh;
  border-right: 1px solid #f0f0f0;
}

.shopcategory-right {
  /* flex-basis: 85%; */
  display: flex;
  flex-direction: column;
  gap: 4vh;
  margin: 4vh 0;
}

.shopcategory-sort {
  display: flex;
  margin: 0 8vw;
  justify-content: space-between;
  align-items: center;
  gap: 4vh;
}

.shopcategory-sort-search-bar {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 20px;
  border-radius: 25px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  width: 100%;
  width: 35vh;
  min-width: 25vw;
  gap: 8px;
  transition: all 0.3s ease;
}

.shopcategory-sort-search-bar:hover {
  border-color: lightgrey;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.shopcategory-sort-search-icon {
  color: #999;
  font-size: 18px;
}


.shopcategory-sort-search input {
  width: calc(100% - 24px);
  color: #333;
  border: none;
  outline: none;
  background: transparent;
  font-size: 16px;
  padding-left: 8px;
}

.shopcategory-sort-search input::placeholder {
  color: #999;
}

.shopcategory-sort-search input:focus::placeholder {
  color: #ccc;
}

.shopcategory-sort-button {
  text-align: center;
  width: 16vw;
  max-width: 16vh;
  padding: 10px 20px;
  color: white;
  background-color: #171717;
}

.shopcateogry-items {
  padding: 0 4vw;
}

.shopcategory-products-parent {
  display: flex;
  flex-direction: column;
  gap: 4vh;
  padding: 4vh 0;
}

.shopcategory-query {
  text-align: center;
}

.shopcategory-showmore {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shopcategory-showmore-results {
  text-align: center;
}

.shopcategory-showmore-results b {
  color: #171717;
}

.shopcategory-showmore-button {
  color: grey;
}

.shopcategory-showmore-button p:hover {
  color: #3a3a3a;
  cursor: pointer;
  text-decoration: none;
  font-weight: 600;
  transition: 0.25s;
}

.shopcategory-showmore-button p:active {
  color: #171717;
  transform: scale(0.975);
}

@media only screen and (max-width: 1250px) {
  .shopcategory {
    flex-direction: column;
  }

  .shopcategory-sort-search-bar {
    width: 35vh;
    min-width: 35vw;
  }
}

@media screen and (max-width: 768px) {
  .shopcategory-left {
    /* flex-basis: 17.5%; */
    display: flex;
    flex-direction: column;
    gap: 4vh;
    border: none;
    /* border-bottom: 1px solid #f0f0f0; */
  }

  .shopcategory-sort {
    display: flex;
    flex-direction: column;
    gap: 2vh;
  }
  .shopcategory-sort-search-bar {
    width: 80vw;
  }
  .shopcategory-sort-button {
    width: 20vw;
    min-width: 12vh;
  }
  .shopcategory-products {
    gap: 4vh;
  }
}
