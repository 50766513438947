.footer {
  left: 0;
  bottom: 0;
  background-color: #1e1e1e;
  color: #f0f0f0;
  text-align: center;
  padding: 2rem;
  font-family: Arial, sans-serif;
}

.footer-form {
  display: flex;
  flex-direction: row;
  gap: 8px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .footer-form {
    flex-direction: column;
  }
}


.footer-form p {
  display: inline;
}

.footer-form-input {
  /* margin-left: 8px; */
  padding: 8px;
  border: 1px solid #3a3a3a;
  background-color: #292929;
  color: #f0f0f0;
}

.footer-form-input:focus {
  outline: none;
}

.footer-form-button {
  padding: 8px 16px;
  border: none;
  background-color: #3a3a3a;
  border: 1px solid #3a3a3a;
  color: white;
  cursor: pointer;
  transition: 0.25s;
}

.footer-form-button:hover {
  background-color: #505050;
}

.footer h2 {
  margin-bottom: 8px;
  color: #fafafa;
}

.footer-information {
  display: flex;
  margin: auto;
}

.footer-information-child {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-links {
  list-style: none;
  padding: 0;
  margin-top: 1rem;
}

.footer-links li {
  display: inline;
  margin: 0 0.5rem;
}

.footer a {
  color: #bbbbbb;
  text-decoration: none;
  font-weight: 700;
}

.footer a:hover {
  color: #ffffff;
  text-decoration: none;
  font-weight: 700;
  transition: 0.25s;
}

.footer-links li a:hover {
  text-decoration: underline;
}

.footer hr {
  margin: 1rem 0;
  border: none;
   border-top: 1px solid #3a3a3a;
  width: 60vw;
  margin: 32px auto;
}

.footer-social {
  margin-top: 16px;
}

.footer-social-icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2vw;
  width: 100%;
}

@media screen and (max-width: 768px) {
  .footer-social-icons {
    gap: 4vw;
  }
}


.footer-icon {
  height: 32px;
  width: 32px;
  color: lightgrey;
}

@keyframes spinIcon {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

.footer-icon.social:hover {
  animation: spinIcon 1.5s infinite;
  transition: 0.5s;
  scale: 1.2;
  cursor: pointer;
  color: white;
}

.footer-icon.envelope {
  height: 1em;
  width: 1em;
}

.footer-icon.facebook:hover {
  color: #1da1f2;
}

.footer-icon.twitter:hover {
  color: #1da1f2;
}
.footer-icon.instagram:hover {
  color: #ee2b7b;
}

.footer-icon.snapchat:hover {
  color: #f7f300;
}

.footer-icon.tiktok:hover {
  color: #f62755;
}

.footer-icon.phone {
  height: 1em;
  width: 1em;
}

.footer-signature p {
  margin-bottom: 8px;
}

.footer-signature p:last-child {
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .footer-information {
    display: block;
  }
}
