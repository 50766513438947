.cartcheckout {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 2vw;
}

.cartcheckout b {
  color: darkgrey;
}

.cartcheckout-child {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 16px;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 32px;
  height: fit-content;
}

.cartcheckout h2 {
  color: #171717;
}

/* New styles for coupon */
.cartcheckout-coupon {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.cartcheckout-coupon input {
  flex-grow: 1;
  padding: 8px;
  background-color: #f0f0f0;
  border: none;
  font-size: 16px;
}

.cartcheckout-coupon input::placeholder {
  color: darkgrey;
}

.cartcheckout-coupon input:focus {
  outline: none;
  transition: 0.125s;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
}

.cartcheckout-coupon button {
  padding: 8px 16px;
  border: none;
  background-color: darkgrey;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: 0.25s;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.cartcheckout-coupon button:hover {
  background: linear-gradient(135deg, #667eea, #764ba2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
  transition: 0.25s;
}

.cartcheckout-coupon button:active {
  background: linear-gradient(135deg, #667eea, #764ba2);
  transform: scale(0.975);
  transition: 0.25s;
}

.cartcheckout-coupon-confirmation {
  color: green;
  font-size: 16px;
  font-weight: 600;
}

.cartcheckout-text {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cartcheckout-text p {
  display: flex;
  justify-content: space-between;
}

.cartcheckout hr {
  border: none;
  border-bottom: 1px solid #e0e0e0;
  margin: 16px 0;
}

.cartcheckout-currency {
  color: #171717;
  font-size: 20px;
  font-weight: 600;
}

.cartcheckout-currency-discount {
  color: red;
}

.cartcheckout-notification {
  color: lightgrey;
  font-size: 16px;
  font-weight: 600;
}

.cartcheckout-confirm {
  border: none;
  border-radius: 5px;
  padding: 16px;
  background-color: darkgrey;
  color: white;
  font-size: 16px;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  cursor: pointer;
}

.cartcheckout-confirm:hover {
  background: linear-gradient(135deg, #667eea, #764ba2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transform: scale(1.025);
  transition: 0.25s;
}

.cartcheckout-confirm:active {
  background: linear-gradient(135deg, #667eea, #764ba2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  transform: scale(0.975);
  transition: 0.25s;
}
