.item {
  position: relative;
  width: 280px;
  background: rgb(252, 252, 252); /* TODO: Color variable */
  border-radius: 16px;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  margin-bottom: auto;
  overflow: hidden;
}

.item:hover {
  transform: scale(1.025);
  transition: 0.6s;
  box-shadow: 0px 20px 15px rgba(0, 0, 0, 0.4);
}

.item * {
  text-decoration: none;
}

.item-image-container {
  position: relative;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  overflow: hidden;
  height: 320px;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}

.item-image {
  overflow: hidden;
  border-top-left-radius: 16px;
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}

.item-reduced {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to top, rgba(255, 0, 0, 0.8), rgba(255, 0, 0, 0));
  color: white;
  text-align: center;
  padding: 8px;
  font-weight: bold;
}

.item-reduced-content {
  display: inline-block;
}

.item-description {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.item-description-title {
  font-weight: 500;
  color: #171717;
}

.item-description-reviews {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: 4px;
  color: darkgrey;
}

.item-description-reviews * {
  font-size: 14px;
}

.item-description-reviews-stars {
  color: lightgrey;
}

.item-description-reviews-star-solid {
  color: orange;
}

.item-description-reviews-star-half {
  color: orange;
}

.item-description-reviews-text {
  font-size: 12px;
  font-weight: 500;
}

.item-stuff {
  flex: 1;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
  margin-top: 4px;
  padding: 0 16px;
}

.item-stuff > * {
  display: flex;
  align-items: center;
}

.item-prices {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.item-price {
  color: #171717; /* TODO: Color variable */
  font-weight: 600;
}

.item-price.reduced {
  color: red;
  font-weight: 700;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
}

.item-price-old {
  color: darkgrey;
  font-size: 14px;
  font-weight: 500;
  text-decoration: line-through;
}

.item-status {
  gap: 8px;
}

.item-status * {
  font-size: 24px;
}

.item-favourite {
  color: red;
}

.item-cart-container {
  position: relative;
  display: inline-block;
}

.item-cart {
  color: #667eea;
}

.item-cart-count {
  position: absolute;
  top: -10px;
  right: -10px;
  background: linear-gradient(135deg, #667eea, #764ba2);
  color: white;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 0.75rem;
  font-weight: bold;
  line-height: 1;
  min-width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid white;
}

@media screen and (max-width: 768px) {
  .item-description {
    display: flex;
    flex-direction: column;
  }

}

@media (max-width: 420px) {
  .item-stuff {
    display: flex;
    flex-wrap: wrap;
  }
}

@media (max-width: 300px) {
  .item-prices {
    display: flex;
    flex-direction: column;
  }
}
